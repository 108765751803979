@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

:root {
  --title-color: #f17821;
  --paragraph-color: #191919;
  --background-color: #f2f2f2;
}

[data-theme="dark"] {
  --title-color: #f17821;
  --paragraph-color: #f2f2f2;
  --background-color: #191919;
}
