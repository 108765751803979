@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.home-logo-pattern {
  min-height: 100vh;
  background-image: url("../img/home of electric pattern.svg") !important;
  background-size: cover;
  background-position: center center;
  background-color: var(--background-color);
}

.home-diagonal-div {
  min-height: 100vh;
  background-color: var(--background-color);
  clip-path: polygon(50% 0, 100% 0, 100% 50%, 50% 100%, 0 100%, 0% 50%);
}

.home-container-text {
  padding-inline: 4rem;
  min-height: 100vh;
  padding-top: 40vh;
  text-align: center;
}

.home-welcome-to {
  font-family: "Montserrat", sans-serif;
  color: var(--title-color);
}

.home-title {
  font-family: "Montserrat", sans-serif;
  color: var(--title-color);
}
