.page-container {
  min-height: 100vh;
  background-color: var(--background-color);
}

.carousel-container {
  min-height: 100vh;
}

.carousel-item {
  text-align: center;
  padding-top: 22vh;
  min-height: 100vh;
}

.carousel-image {
  height: 18vh;
}

.carousel-caption-position {
  top: 40vh;
}

.carousel-title {
  font-family: "Montserrat", sans-serif;
  color: var(--title-color) !important;
}

.carousel-text {
  font-family: "Poppins", sans-serif;
  color: var(--paragraph-color) !important;
}
