@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.footer-container {
  min-height: auto;
  background-color: var(--background-color);
  font-family: "Montserrat", sans-serif;
  color: var(--title-color);
  padding-inline: 10vw;
}

.footer-content {
  text-align: center;
  padding-top: 5vh;
}

.footer-content h4 {
  padding-top: 3vh;
  border-top: solid;
}

.footer-bottom {
  text-align: center;
}
.footer-bottom p {
  margin-bottom: 0;
  padding-bottom: 7vh;
  text-align: center;
}
