@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.welcome-container {
  background-color: var(--background-color);
  text-align: center;
  padding-inline: 10vw;
  padding-block: 5rem;
}

.welcome-title {
  font-family: "Montserrat", sans-serif;
  color: var(--title-color);
  padding-bottom: 3vh;
}

.welcome-text {
  font-family: "Poppins", sans-serif;
  color: var(--paragraph-color);
  padding-bottom: 2.5vh;
}

.welcome-home-of-electric-img {
  height: 10rem;
}
