@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.contact-data-section-container {
  min-height: 100vh;
  background-color: var(--background-color);
  padding-block: 8vh;
}

.contact-data-container {
  padding-top: 6vh;
}

.contact-data-icon-container {
  display: flex;
  justify-content: center;
  padding-bottom: 5vh;
}

.contact-data-icon {
  height: 6rem;
}

.contact-data-button-container {
  display: flex;
  justify-content: center;
}

.contact-data-button {
  background-color: transparent;
  color: var(--title-color);
  border: 5px solid var(--title-color);
  border-radius: 5px;
  padding-block: 0.5rem;
  width: 80vw;
  font-family: "Montserrat", sans-serif;
}

.contact-data-button:hover {
  background-color: var(--title-color);
  color: var(--background-color);
}

.contact-data-button-text {
  margin: 0;
}
